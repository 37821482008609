import React from "react";
import { Button, Grid } from "@mui/material";
import { useAddNewMonth } from "./AddNewMonth.hooks";
import GoalSetter from "../Modals/GoalSetter/GoalSetter";
import { getMonthName } from "../../../Utils/Time.utils";
import { buttonStyles } from "../../../Constants";
import classes from "./AddNewMonth.module.css";

/**
 * Component to add a new month
 */
const AddNewMonth: React.FC = () => {
  // custom hook to get all the necessary data for the component
  const addNewMonth = useAddNewMonth();

  return (
    <>
      {addNewMonth.showGoalEdit && (
        <GoalSetter {...addNewMonth.goalSetterProps} />
      )}
      <Grid item lg={6} xs={12}>
        <div className={`${classes["add-new-month_container"]}`}>
          <h2 className={`${classes["header"]}`}>there is nothing yet</h2>
          <div className={classes["plus"]}>
            <Button
              onClick={addNewMonth.handleOpenModal}
              style={buttonStyles}
              variant="outlined"
            >
              initialize month
            </Button>
          </div>
          <h3 className={`${classes["subheader"]}`}>
            {`${getMonthName(addNewMonth.selectedMonth)} ${
              addNewMonth.selectedYear
            }`}
          </h3>
        </div>
      </Grid>
    </>
  );
};

export default AddNewMonth;
