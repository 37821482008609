import React from "react";
import { useHourlyTypes } from "./HourlyTypes.hook";
import { BarChart } from "@mui/x-charts";
import { ChartIsLoading, ChartLegendList } from "../Charts";
import { getXLabelsIntervals } from "../Charts.utils";
import { HOURS } from "../../../../../../Constants";
import classes from "./HourlyTypes.module.css";
import classesChart from "../Charts.module.css";
import { SingleDayData } from "../../../../../../Types/WaterTracker.types";

/**
 * no data message for the legend list
 */
const NoData = () => {
  return (
    <div className={`${classesChart["no-data-container"]}`}>
      <svg
        className={`${classesChart["icon"]} mb-4`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path d="M160 80c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 352c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-352zM0 272c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 160c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48L0 272zM368 96l32 0c26.5 0 48 21.5 48 48l0 288c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48z" />
      </svg>

      <h4 className={`${classesChart["no-data-text"]}`}>
        {`start trackng your water intake`}
        <br />
        {`to see the chart`}
      </h4>
    </div>
  );
};

interface HourlyTypesProps {
  colors: string[];
  day: SingleDayData | null;
}

/**
 * Hourly Types Component: displays the hourly activity of different liquid types
 */
const HourlyTypes: React.FC<HourlyTypesProps> = ({ colors, day }) => {
  const hourlyTypes = useHourlyTypes(colors, day);

  const chartEl = (
    <div className={`${classes["chart-container"]}`}>
      <BarChart
        sx={{ "&&": { touchAction: "auto" } }}
        series={hourlyTypes.data}
        grid={{ horizontal: true }}
        // TODO: configure tooltip to hide 0 values
        tooltip={{ trigger: "none" }}
        xAxis={[
          {
            label: "hours",
            scaleType: "band",
            data: HOURS,

            disableTicks: true,
            tickLabelInterval: hourlyTypes.isMobile
              ? getXLabelsIntervals
              : // 0 is not shown without ternary operator
                (value) => (value ? value : "0"),
          },
        ]}
        yAxis={[
          {
            label: "ml",
            labelStyle: { transform: "rotate(0deg) translate(0.5rem, -38%)" },
            disableLine: true,
            disableTicks: true,
          },
        ]}
      />
    </div>
  );

  const chartContent = hourlyTypes.data.length > 0 ? chartEl : <NoData />;

  // show loading spinner if content is loading
  const chart = hourlyTypes.contentIsLoading ? (
    <div className={classes["loading-container"]}>
      <ChartIsLoading />
    </div>
  ) : (
    chartContent
  );

  const chartLegendListContent = hourlyTypes.isMobile &&
    !hourlyTypes.isDayEmpty && (
      <>
        <h3 className={`${classes["title"]} my-4`}>My drink list: </h3>
        <ChartLegendList data={hourlyTypes.listData} />
      </>
    );

  return (
    <div className={`${classes["container"]} `}>
      <h3 className={classes["title"]}>Hourly types</h3>
      {chart}
      {chartLegendListContent}
    </div>
  );
};

export { HourlyTypes };
