import React from "react";
import { useAddNewMonth } from "../../AddNewMonth/AddNewMonth.hooks";
import { Button, Skeleton } from "@mui/material";
import GoalSetter from "../../Modals/GoalSetter/GoalSetter";
import { getDailyAmountSum } from "../../WaterTracker.utils";
import { buttonStyles } from "../../../../Constants";
import { Month, SingleDayData } from "../../../../Types/WaterTracker.types";
import classes from "./ProgressBarRound.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Reducers/Store";

interface ProgressBarRoundProp {
  day: SingleDayData | null;
  month: Month | null;
}

const ProgressBarRound: React.FC<ProgressBarRoundProp> = ({ day, month }) => {
  const { initialLoading, contentIsLoading } = useSelector(
    (state: RootState) => state.waterTracker
  );
  const addNewMonth = useAddNewMonth();

  const amount = day ? getDailyAmountSum(day) : null;
  const currentGoal = day ? day.goal : null;

  const percentege =
    amount && currentGoal
      ? parseFloat(((amount / currentGoal) * 100).toFixed(1))
      : 0;

  const background = `radial-gradient(closest-side, rgb(247, 247, 247) 77%, transparent 0% 100%), conic-gradient(rgb(116, 235, 213) ${percentege}%, rgb(210, 234, 241) 0)`;

  return initialLoading || contentIsLoading ? (
    <Skeleton variant="circular" width={"12rem"} height={"12rem"} />
  ) : (
    <>
      {addNewMonth.showGoalEdit && (
        <GoalSetter {...addNewMonth.goalSetterProps} />
      )}
      <div
        className={classes["progress-bar"]}
        style={{ background: background }}
      >
        <div className={classes["text-wrapper"]}>
          {month && (
            <>
              <div className={classes["text-numbers-top"]}>{`${
                amount ? amount : 0
              } `}</div>
              <div className={classes["text-numbers-bottom"]}>{`/ ${
                currentGoal ? currentGoal : 0
              } ml`}</div>
            </>
          )}

          <div className={classes["text-date"]}>
            {!month && (
              <Button
                onClick={addNewMonth.handleOpenModal}
                variant="text"
                style={buttonStyles}
              >
                start tracking
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressBarRound;
