import React from "react";
import { Grid, Skeleton } from "@mui/material";
import classes from "./Week.module.css";

interface WeekStatsProps {
  stats: {
    days: number;
    finished: number;
    average: number;
    max: number;
  } | null;
  isLoading: boolean;
}

// show week stats if there is data, otherwise show no data
const WeekStats: React.FC<WeekStatsProps> = ({ stats, isLoading }) => {
  const loading = (
    <Skeleton className="mt-2" variant={"rounded"} width={100} height={14} />
  );

  const noData = <span>{`no data`}</span>;

  const averageEl = stats ? (
    <div>
      <span>{`max `}</span>
      <span className={`${classes["highlighted"]}`}>{`${stats.max}`}</span>
      <span>{` ml`}</span>
    </div>
  ) : (
    noData
  );

  const average = isLoading ? loading : averageEl;

  const maxEl = stats ? (
    <div>
      <span>{`max `}</span>
      <span className={`${classes["highlighted"]}`}>{`${stats.max}`}</span>
      <span>{` ml`}</span>
    </div>
  ) : (
    noData
  );

  const max = isLoading ? loading : maxEl;

  const finishedEl = stats ? (
    <div>
      <span className={`${classes["highlighted"]}`}>{`${stats.finished}`}</span>
      <span>{` of `}</span>
      <span className={`${classes["highlighted"]}`}>{`${stats.days}`}</span>
      <span>{` goals reached`}</span>
    </div>
  ) : (
    noData
  );

  const finished = isLoading ? loading : finishedEl;

  return (
    <Grid container>
      <Grid item sm={4} xs={6}>
        <div className={`${classes["stats"]}`}>
          <div className={classes["circle"]}>
            <svg
              className={`${classes["icon"]}`}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
            >
              <path
                d="M464,256c0-114.9-93.1-208-208-208S48,141.1,48,256s93.1,208,208,208S464,370.9,464,256z M0,256C0,114.6,114.6,0,256,0
  s256,114.6,256,256S397.4,512,256,512S0,397.4,0,256z"
              />
              <path
                d="M12.9,466.2c-13,13-15.8,31-6.4,40.4c9.4,9.4,27.4,6.5,40.4-6.4L499.5,47.4c13-13,15.8-31,6.4-40.4s-27.4-6.5-40.4,6.4
  L12.9,466.2z"
              />
            </svg>
          </div>
          {average}
        </div>
      </Grid>
      <Grid item sm={4} xs={6}>
        <div className={`${classes["stats"]}`}>
          <div className={classes["circle"]}>
            <svg
              className={`${classes["icon"]}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path d="M384 160c-17.7 0-32-14.3-32-32s14.3-32 32-32l160 0c17.7 0 32 14.3 32 32l0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-82.7L342.6 374.6c-12.5 12.5-32.8 12.5-45.3 0L192 269.3 54.6 406.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160c12.5-12.5 32.8-12.5 45.3 0L320 306.7 466.7 160 384 160z" />
            </svg>
          </div>
          {max}
        </div>
      </Grid>

      <Grid item sm={4} xs={12}>
        <div className={`${classes["stats"]}`}>
          <div className={classes["circle"]}>
            <svg
              className={`${classes["icon"]}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>
          {finished}
        </div>
      </Grid>
    </Grid>
  );
};

export { WeekStats };
