import React from "react";
import { Grid, GridSize, Skeleton } from "@mui/material";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import classes from "./MonthControls.module.css";

/**
 * Skeleton component for month controls if data is loading
 */
const MonthControlsSkeleton: React.FC = () => {
  // Grid item style properties
  const gridItemStyle = {
    item: true,
    xs: 6 as boolean | GridSize | undefined,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  // Generate 4 skeleton elements for month statistics
  const statsSkeleton = _.times(4, () => {
    return (
      <Grid {...gridItemStyle} key={uuid()}>
        <Skeleton
          className="m-3"
          variant="rounded"
          width={"7rem"}
          height={"1.5rem"}
        />
      </Grid>
    );
  });

  return (
    <Grid item lg={6} xs={12}>
      <div className={classes["month-controls-container"]}>
        <Grid container>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Skeleton
              className="mb-7"
              variant="rounded"
              width={"10rem"}
              height={"1.5rem"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Skeleton
              className="mb-7"
              variant="rounded"
              width={"14rem"}
              height={"3rem"}
            />
          </Grid>
          {statsSkeleton}
        </Grid>
      </div>
    </Grid>
  );
};

export { MonthControlsSkeleton };
