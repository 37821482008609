import { getDailyAmountSum } from "../../WaterTracker.utils";
import { SingleDayData } from "../../../../Types/WaterTracker.types";

/**
 * This function calculates the average, max, and finished days of the week
 * @param week week data
 * @returns object with the average, max, and finished days
 */
function getWeekStats(week: Array<SingleDayData>) {
  // Initialize the average, max, and finished days
  const weekStats = {
    days: week.length,
    finished: 0,
    average: 0,
    max: 0,
  };

  // If there is no data, return the default values
  if (week.length === 0) {
    return weekStats;
  }

  // initialize the sum, counter and finished days
  let sum = 0;
  let counter = 0;
  let finished = 0;

  // Iterate over the week data
  week.forEach((day) => {
    // calculate the amount of the day
    const amount = getDailyAmountSum(day);

    // calculate the sum and counter if the current amount is greater than 0
    if (amount > 0) {
      counter++;
      sum += amount;
    }

    // count finished days
    if (amount >= day.goal) {
      finished++;
    }
  });

  // Set the average, max, and finished days
  weekStats.finished = finished;
  weekStats.average = sum > 0 ? Math.trunc(sum / counter) : 0;
  weekStats.max = Math.max(...week.map((d) => getDailyAmountSum(d)));

  return weekStats;
}

/**
 * This function is needed for getting extra space for height in the chart,
 * so the highest number is not sticked to the top of the chart
 * @param num actual height of the chart
 * @param divider the number to divide the height by
 * @returns the height of the chart with extra space
 * @example getNumberDivisible(1000, 250)
 * // returns 1250 progress bar is not sticked to the top
 */
function getExtraHeight(num: number, divider: number): number {
  // no negative numbers and nulls
  if (num <= 0 || divider <= 0) return -1;

  // Check if the number is already divisible by 250
  if (num % 250 === 0) {
    return num + divider;
  }

  // Calculate the remainder
  const remainder = num % 250;

  // Add the remainder to get the next divisible number
  return num + (divider - remainder);
}

export { getWeekStats, getExtraHeight };
