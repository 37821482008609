import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Reducers/Store";
import { getMonthStats } from "../../WaterTracker.utils";
import { Grid, GridSize } from "@mui/material";
import classes from "./MonthStatistics.module.css";

/**
 * Component for displaying month statistics
 */
const MonthStatistics: React.FC = () => {
  const { month } = useSelector((state: RootState) => state.waterTracker);
  const monthStats = month ? getMonthStats(month) : null;

  // Grid item style properties
  const gridItemStyle = {
    item: true,
    xs: 6 as boolean | GridSize | undefined,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  // Element for average amount of water drunk in a month or 0 if monthStats is null
  const averageAmountEl = monthStats ? (
    <span className={classes["highlighted"]}>{monthStats.averageAmount}</span>
  ) : (
    0
  );

  // Element for max amount of water drunk in a day of the month or 0 if monthStats is null
  const maxAmountEl = monthStats ? (
    <span className={classes["highlighted"]}>{monthStats.maxAmount}</span>
  ) : (
    0
  );

  // Element for goals reached in a month or 0 if monthStats is null
  const goalsReachedEl = monthStats ? (
    <span className={classes["highlighted"]}>
      {monthStats.goalReachedCounter}/{monthStats.daysInMonth}
    </span>
  ) : (
    0
  );

  // Element for days left in current month or empty if monthStats is null
  const daysLeftEl =
    monthStats && monthStats.daysInMonthLeft ? (
      <Grid {...gridItemStyle}>
        <div className={classes["month-stats"]}>
          <span className={classes["highlighted"]}>
            {monthStats.daysInMonthLeft}
          </span>{" "}
          days left
        </div>
      </Grid>
    ) : (
      <></>
    );

  return (
    monthStats && (
      <>
        <Grid {...gridItemStyle}>
          <div className={classes["month-stats"]}>max {maxAmountEl} ml</div>
        </Grid>
        <Grid {...gridItemStyle}>
          <div className={classes["month-stats"]}>Ø {averageAmountEl} ml</div>
        </Grid>
        <Grid {...gridItemStyle}>
          <div className={classes["month-stats"]}>{goalsReachedEl} goals</div>
        </Grid>
        {daysLeftEl}
      </>
    )
  );
};

export default MonthStatistics;
