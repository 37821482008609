import React from "react";
import classes from "./Footer.module.css";

const Footer: React.FC = () => {
  return (
    <div
      className={`${classes["wrapper"]} min-h-20 flex flex-col justify-center content-center mt-2`}
    >
      <div className={classes["message"]}>
        Your health is your responsibility
      </div>
      <div
        className={classes["copyright"]}
      >{`copyright © get-healthy.dev ${new Date().getFullYear()}`}</div>
    </div>
  );
};

export default Footer;
