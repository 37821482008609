import React from "react";
import BluredOverlay from "../../../Overlays/BluredOverlay/BluredOverlay";
import { Button } from "@mui/material";
import { buttonStyles } from "../../../../Constants";
import NumberSelection from "../../Today/NumberPicker/NumberSelection";
import classes from "../index.module.css";

interface GoalSetterProps {
  handleSetNumber: (value: number) => void;
  addEmptyMonth: () => Promise<void>;
  handleOnCancel: () => void;
  goal: number;
  isValidationActive: boolean;
}

const MIN_VALUE = 500;

/**
 * Modal for setting a goal for a month
 */
const GoalSetter: React.FC<GoalSetterProps> = ({
  handleSetNumber,
  addEmptyMonth,
  handleOnCancel,
  goal,
  isValidationActive,
}) => {
  return (
    <BluredOverlay>
      <div className={classes["modal-wrapper"]}>
        <NumberSelection
          setNumber={handleSetNumber}
          value={goal}
          headerName={"default month goal"}
        />
        <div className={classes["warning-message"]}>
          {isValidationActive && goal < MIN_VALUE
            ? `at least ${MIN_VALUE} ml`
            : ""}
        </div>
        <div className={classes["control-buttons-container"]}>
          <Button
            onClick={addEmptyMonth}
            style={buttonStyles}
            variant="outlined"
          >
            OK
          </Button>
          <Button
            onClick={handleOnCancel}
            style={buttonStyles}
            variant="outlined"
          >
            Cancel
          </Button>
        </div>
      </div>
    </BluredOverlay>
  );
};

export default GoalSetter;
