import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth";
import { useRef } from "react";
import { resetState } from "../../Reducers/WaterTracker/WaterTracker";
import RoutesValues from "../../Routes/Routes.enums";

const useMenu = () => {
  // dispatch for managing states
  const dispatch = useDispatch();

  // location for displaying the neccessary menu items
  const location = useLocation();

  // logout function for logout button
  const { logout } = useAuth();

  // navigate function for using on nav items
  const navigate = useNavigate();

  // get reference object for menu to detect clicks
  const menuRef = useRef<HTMLDivElement>(null);

  // state for menu open/close
  const [open, setOpen] = React.useState(false);

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  // observe clicks outside the menu
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // get current pathname
  const { pathname } = location;

  // open menu
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // close menu
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // logout user and navigate to sign in page
  const handleLogout = async () => {
    await logout();
    navigate(RoutesValues.signIn);
  };

  // navigate to time traverse page
  const navigateToTimeTraverse = () => {
    // reset state to avoid displaying old data
    dispatch(resetState());

    // navigate to time traverse page
    navigate(RoutesValues.monthTraverse);
  };

  // navigate to current month page
  const navigateToCurrentMonth = () => {
    // reset state to avoid displaying old data
    dispatch(resetState());

    // navigate to current month page
    navigate(RoutesValues.currentMonth);
  };

  return {
    pathname,
    menuRef,
    open,
    navigateToTimeTraverse,
    navigateToCurrentMonth,
    handleLogout,
    handleDrawerOpen,
    handleDrawerClose,
  };
};

export { useMenu };
