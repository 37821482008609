import React from "react";
import { useMonthControls } from "./MonthControls.hooks";
import { Grid } from "@mui/material";
import MonthStatistics from "./MonthStatistics/MonthStatistics";
import { getMonthName } from "../../../Utils/Time.utils";
import { TimeTraverseModal } from "../Modals/TimeTraverseModal/TimeTraverseModal";
import classes from "./MonthControls.module.css";

/**
 * MonthControls component for month overview and time navigation
 */
const MonthControls: React.FC = () => {
  // get all necessary data and functions for month controls
  const monthControls = useMonthControls();

  return (
    <>
      {monthControls.showTimeTraverse && (
        <TimeTraverseModal
          loadSetTime={monthControls.loadSetTime}
          closeModal={monthControls.closeModal}
          timeTraverseProps={monthControls.timeTraverseProps}
        />
      )}
      <Grid item lg={6} xs={12}>
        <div className={classes["month-controls-container"]}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes["header"]}>month overview</div>
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <div
                onClick={monthControls.openModal}
                className={classes["button"]}
              >
                <div className={classes["button-label"]}>time traverse</div>
                <div className={classes["button-output"]}>{`${getMonthName(
                  monthControls.selectedMonth
                )} / ${monthControls.selectedYear}`}</div>
              </div>
            </Grid>
            <MonthStatistics />
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default MonthControls;
