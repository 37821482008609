import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  formatDateToString,
  formatStringToDate,
  getMonndayAndSunday,
  getMonthName,
} from "../../../../Utils/Time.utils";
import { getWeekStats } from "./Week.utils";
import { SingleDayData } from "../../../../Types/WaterTracker.types";
import { RootState } from "../../../../Reducers/Store";
import { calculateColor } from "../../../../Utils/Color.utils";
import { BarItemIdentifier } from "@mui/x-charts";
import RoutesValues from "../../../../Routes/Routes.enums";

const useWeek = (week: Array<SingleDayData> | null) => {
  const { initialLoading, contentIsLoading } = useSelector(
    (state: RootState) => state.waterTracker
  );
  const navigate = useNavigate();
  const location = useLocation();

  // set week data for chart
  const chartData = week
    ? week.map((day) => {
        // tranform activity to array with numbers
        const activityArray = day.activity
          ? day.activity.map((hour) => {
              if (Array.isArray(hour)) {
                return hour.reduce((a, b) => a + b.amount, 0);
              } else {
                return 0;
              }
            })
          : [];

        // sum the array
        const activitySum = activityArray.reduce((a, b) => a + b, 0);

        // calculate the color of the progress bar
        const percentage = (activitySum / day.goal) * 100;
        const { hex } = calculateColor(percentage, 1);

        return {
          date: day.date,
          amount: activitySum,
          goal: day.goal,
          color: hex,
        };
      })
    : [];

  // check if data is loading
  const isLoading = initialLoading || contentIsLoading;

  // get the week stats
  const stats = week && getWeekStats(week);

  // get the date of the week
  const date = week
    ? formatStringToDate(week[0].date, "DD.MM.YYYY")
    : new Date();

  // get monday and sunday of the week
  const { monday, sunday } = getMonndayAndSunday(date);

  // get previeous and next month names if there are overlapping months
  const prevMonth = `${monday ? getMonthName(monday.getMonth()) : ""}`;
  const lastMonth = `${sunday ? getMonthName(sunday.getMonth()) : ""}`;

  // date of week start and end
  const weekStart = `${monday ? monday.getDate() : ""} ${
    prevMonth === lastMonth ? "" : prevMonth
  }`;
  const weekEnd = `${sunday ? sunday.getDate() : ""} ${
    sunday ? getMonthName(sunday.getMonth()) : ""
  }`;

  // week info
  const weekInfo = week ? `Week: ${weekStart} - ${weekEnd}` : "Week";

  const dates = chartData.map((day) => day.date);

  /**
   * set all the neccessary data and open day modal
   */
  const openDay = (
    _: React.MouseEvent<SVGElement, MouseEvent>,
    barItemIdentifier: BarItemIdentifier
  ) => {
    const date = dates.at(barItemIdentifier.dataIndex);
    if (!date) return;

    // Check if date is today date, if so smoothly scroll to the top and it's current month view
    const today = formatDateToString(new Date(), "DD.MM.YYYY");
    const isToday = today === date;
    const isCurrentMonth = location.pathname === RoutesValues.currentMonth;
    if (isToday && isCurrentMonth) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    // Navigate to day with dynamic route
    navigate(`/day/${date}`);
  };

  return { weekInfo, stats, isLoading, chartData, openDay };
};

export { useWeek };
