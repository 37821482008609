import React from "react";
import { useWeek } from "./Week.hooks";
import { Grid } from "@mui/material";
import {
  BarPlot,
  ChartsGrid,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { NoData } from "../Day/Charts/Charts";
import { ContentIsLoading } from "../ContentIsLoading";
import { WeekStats } from "./Week.components";
import { WEEKDAY_SHORT_NAMES } from "../../../../Constants";
import { SingleDayData } from "../../../../Types/WaterTracker.types";
import classes from "./Week.module.css";

interface WeekProps {
  weekData: Array<SingleDayData> | null;
}

/**
 * Component for displaying a week of water tracking data
 */
const Week: React.FC<WeekProps> = ({ weekData }) => {
  const week = useWeek(weekData);

  // TODO: check it later, colorMap might be bugged
  // const colors = week.chartData.map((day) => day.color);
  const amounts = week.chartData.map((day) => day.amount);
  const goals = week.chartData.map((day) => day.goal);

  const chart =
    week.chartData.length > 0 ? (
      <ResponsiveChartContainer
        sx={{ "&&": { touchAction: "auto" } }}
        series={[
          {
            type: "bar",
            data: amounts,
            valueFormatter: (value) => {
              return `amount: ${value} ml`;
            },
          },
          {
            type: "line",
            data: goals,
            valueFormatter: (value) => {
              return `goal: ${value} ml`;
            },
          },
        ]}
        xAxis={[
          {
            scaleType: "band",
            data: WEEKDAY_SHORT_NAMES,
            disableTicks: true,
            tickLabelInterval: (value) => (value ? value : "0"),
            id: "weekdays-x-axis",
          },
        ]}
        yAxis={[
          {
            label: "ml",
            labelStyle: {
              transform: "rotate(0deg) translate(0.5rem, -38%)",
            },
            disableLine: true,
            disableTicks: true,
            id: "daily-amounts-y-axis",
            // TODO: I think it's an issue in the library, mixed colors are not displayed
            // BUG: e.g. two greens in the middle of the week surrounded by reds, output is all red
            // data: amounts,
            // colorMap: {
            //   type: "ordinal",
            //   colors: colors,
            //   values: amounts,
            // },
          },
        ]}
      >
        <BarPlot onItemClick={week.openDay} />
        <ChartsGrid horizontal />
        <ChartsXAxis position="bottom" axisId="weekdays-x-axis" />
        <ChartsYAxis position="left" axisId="daily-amounts-y-axis" />
        <LinePlot />
        <MarkPlot />
        <ChartsTooltip />
      </ResponsiveChartContainer>
    ) : (
      <NoData />
    );

  const content = week.isLoading ? <ContentIsLoading /> : chart;

  return (
    <Grid item lg={6} xs={12}>
      <div className={`${classes["week-view-container"]}`}>
        <h3 className={`${classes["title"]}`}>{week.weekInfo}</h3>
        <div className={classes["chart-container"]}>{content}</div>
        <Grid container>
          <Grid item xs={12}>
            <div className={`${classes["week-stats"]}`}>
              <WeekStats stats={week.stats} isLoading={week.isLoading} />
            </div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export { Week };
