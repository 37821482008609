import { useState } from "react";
import { useAuth } from "../../../Auth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Reducers/Store";
import { setControls } from "../../../Reducers/WaterTracker/WaterTracker";
import { getHeaders } from "../../../Utils/Request.utils";
import {
  createMonthData,
  setControlValue,
} from "../../../Reducers/WaterTracker/WaterTracker.thunks";
import { ControlKeys } from "../../../Constants";
import { MonthIndex } from "../../../Types/Global.types";

const useAddNewMonth = () => {
  const { currentUser } = useAuth();
  const { selectedMonth, selectedYear, controls } = useSelector(
    (state: RootState) => state.waterTracker
  );
  const [showGoalEdit, setShowGoalEdit] = useState(false);
  const [isValidationActive, setIsValidationActive] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const goal = controls.goal ? controls.goal : 0;

  const handleSetNumber = (value: number) => {
    const controlsUpdated = {
      amount: controls.amount,
      goal: value,
      type: controls.type,
    };
    dispatch(setControls(controlsUpdated));
  };

  const handleOnCancel = () => {
    setShowGoalEdit(false);
  };

  /**
   * initialize month data to start tracking
   */
  const addEmptyMonth = async () => {
    // get token and set validation check to true
    const token = await currentUser?.getIdToken();
    setIsValidationActive(true);

    // validate goal and check if token is available
    if (!goal || goal < 500 || !token) return;

    // set headers and payload data
    const headers = getHeaders(token);
    const payloadData = { value: goal, controlValueType: ControlKeys.goal };

    // set goal value for the month initialization
    await dispatch(
      setControlValue({ payloadData: payloadData, headers: headers })
    );

    // set payload data for the month initialization
    const payload = {
      year: selectedYear,
      month: selectedMonth as MonthIndex,
      goal: goal,
    };

    // create month data with emtpy days to start tracking
    await dispatch(createMonthData({ payload: payload, headers: headers }));

    // close the modal and reset validation check
    setShowGoalEdit(false);
    setIsValidationActive(false);
  };

  // show goal edit modal
  const handleOpenModal = () => {
    setShowGoalEdit(true);
  };

  // props for GoalSetter component
  const goalSetterProps = {
    handleSetNumber,
    addEmptyMonth,
    handleOnCancel,
    goal,
    isValidationActive,
  };

  return {
    handleOpenModal,
    goalSetterProps,
    showGoalEdit,
    selectedMonth,
    selectedYear,
  };
};

export { useAddNewMonth };
