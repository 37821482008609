import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Reducers/Store";
import { BarChart } from "@mui/x-charts";
import { Grid } from "@mui/material";
import { MonthInfos } from "../MonthInfos/MonthInfos";
import { ContentIsLoading } from "../ContentIsLoading";
import {
  formatDateToString,
  formatStringToDate,
} from "../../../../Utils/Time.utils";
import { getDailyAmountSum } from "../../WaterTracker.utils";
import { TODAY } from "../../../../Constants";
import classes from "./MonthChart.module.css";
import { NoData } from "../Day/Charts/Charts";

/**
 * Component for displaying month chart and statistics
 */
const MonthChart: React.FC = () => {
  const { month, initialLoading, contentIsLoading } = useSelector(
    (state: RootState) => state.waterTracker
  );

  // get current month title
  const title = formatDateToString(TODAY, "MMMM YYYY");

  // extract values from collection
  const monthValues = month ? Object.values(month) : null;
  const monthArray = monthValues ? Object.values(monthValues[0]) : [];

  // const monthAmounts = [] as Array<{ label: string; value: number }>;
  const monthAmounts = [] as number[];
  const xLabels = [] as string[];

  // extract current month string
  const currentMonth = formatDateToString(TODAY, "MM-YYYY");

  // iterate over month data and populate amount values and x labels
  for (const week of monthArray) {
    for (const day of week) {
      // get date object from string
      const date = formatStringToDate(day.date, "DD.MM.YYYY");
      const monthToCheck = formatDateToString(date, "MM-YYYY");

      // if current month then push amount and date to arrays
      if (currentMonth === monthToCheck) {
        const amount = getDailyAmountSum(day);
        monthAmounts.push(amount);
        xLabels.push(formatDateToString(date, "D"));
      }
    }
  }

  // function for displaying x labels
  const getXLabelsIntervals = (_: string, index: number) => index % 2 === 0;

  // check if data is loading
  const isLoading = initialLoading || contentIsLoading;

  const chart =
    monthAmounts.length > 0 ? (
      <BarChart
        sx={{ "&&": { touchAction: "auto" } }}
        grid={{ horizontal: true }}
        series={[
          {
            data: monthAmounts,
            type: "bar",
            valueFormatter: (value) => `${value} ml`,
          },
        ]}
        xAxis={[
          {
            scaleType: "band",
            data: xLabels,
            dataKey: "date",
            disableTicks: true,
            tickLabelInterval: getXLabelsIntervals,
          },
        ]}
        yAxis={[
          {
            label: "ml",
            labelStyle: {
              transform: "rotate(0deg) translate(0.5rem, -38%)",
            },
            disableLine: true,
            disableTicks: true,
            tickLabelInterval(value, index) {
              return value > 0;
            },
          },
        ]}
      />
    ) : (
      <NoData />
    );

  const content = isLoading ? <ContentIsLoading /> : chart;

  return (
    <Grid item lg={6} xs={12}>
      <div className={`${classes["container"]} flex flex-col justify-center`}>
        <h3
          className={`${classes["title"]} text-center mb-3`}
        >{`${title} - overview`}</h3>
        <div className={`${classes["chart-container"]}`}>{content}</div>
        <MonthInfos />
      </div>
    </Grid>
  );
};

export { MonthChart };
